
// Components
import { Component, Prop, Vue } from "nuxt-property-decorator";
import EnergyLegend from "~/components/organisms/display/EnergyLegend.vue";
import HeatingStatistics from "~/components/organisms/display/HeatingStatistics.vue";
import Footnotes from "~/components/organisms/text/Footnotes.vue";

import { OilRequirements } from "~/types/OilRequirement";

@Component({
  components: {
    Footnotes,
    EnergyLegend,
    HeatingStatistics,
  },
})
export default class HeatingOilTable extends Vue {
  @Prop() oilRequirements: OilRequirements;
  energyClasses = ["A+", "A", "B", "C", "D", "E", "F", "G", "H"];
  sizes = [80, 100, 120, 140, 160, 180, 200, 220, 240];

  getRequirement(energyClass, size) {
    return this.oilRequirements[energyClass]?.[size]?.requirement ?? "-";
  }

  getPrice(energyClass, size) {
    return this.oilRequirements[energyClass]?.[size]?.price ?? "-";
  }
}
