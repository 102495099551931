var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"heating-oil-table"},[_c('table',{staticClass:"heating-oil-table__table"},[_c('thead',[_c('tr',{staticClass:"heating-oil-table__header-row"},[_c('th',{staticClass:"heating-oil-table__header-cell heating-oil-table__header-cell--sticky"}),_vm._v(" "),_vm._l((_vm.sizes),function(size){return _c('th',{key:size,staticClass:"heating-oil-table__header-cell"},[_vm._v("\n          "+_vm._s(size)+" m²\n        ")])})],2)]),_vm._v(" "),_c('tbody',_vm._l((_vm.energyClasses),function(energyClass){return _c('tr',{key:energyClass,staticClass:"heating-oil-table__row"},[_c('td',{class:[
            'heating-oil-table__cell',
            'heating-oil-table__cell--sticky',
            `heating-oil-table__cell--${energyClass
              .toLowerCase()
              .replace('+', 'plus')}`,
          ]},[_c('span',{staticClass:"heating-oil-table__grade-label"},[_vm._v(_vm._s(energyClass))])]),_vm._v(" "),_vm._l((_vm.sizes),function(size){return _c('td',{key:`${energyClass}-${size}`,staticClass:"heating-oil-table__cell"},[_c('span',{staticClass:"heating-oil-table__requirement"},[_vm._v(_vm._s(_vm.getRequirement(energyClass, size))+" L")]),_vm._v(" "),_c('span',{staticClass:"heating-oil-table__price"},[_vm._v("("+_vm._s(_vm.getPrice(energyClass, size))+" € / Jahr)")])])})],2)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }